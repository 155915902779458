@import './variables';

body {
  font-family: 'IBMPlexRegular', sans-serif;
  font-size: 17px;
  overflow-x: hidden;

  p {
    font-size: 16px;
  }

  h1 {
    font-size: 60px;
    color: $white;
    line-height: 70px;
    font-family: 'IBMPlexLight', sans-serif;
  }

  h2 {
    font-size: 26px;
    font-weight: 400;
    line-height: 31px;
    &.form-title {
      font-family: 'IBMPlexBold';
      font-size: 35px;
    }
    &.text-heading {
      font-family: 'IBMPlexBold';
      font-size: 25px;
      font-weight: 300;
    }
  }
}

.text-bold {
  font-weight: bold;
}

//alignment
.text-align-center {
  text-align: center;
}
.text-align-right {
  text-align: right;
}

label {
  //font-weight: bold;
  color: #525252;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.36px;
}

@media screen and (max-width: 600px) {
  .menu-container {
    p {
      line-height: 10px;
      font-size: 10px !important;
    }
  }
}
