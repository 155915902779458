/* You can add global styles to this file, and also import other style files */
/* bootstrap imports */

@import 'assets/css/_variables';
// Required
@import '../node_modules/bootstrap/scss/bootstrap';

@import 'assets/css/_layout';
@import 'assets/css/_fonts';
@import 'assets/css/_helper';
@import 'assets/css/_inputs';
@import 'assets/css/_typography';
@import 'assets/css/_tables';
