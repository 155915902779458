@import '../fonts/fontawesome/scss/fontawesome.scss';
@import '../fonts/fontawesome/scss/light.scss';
@import '../fonts/fontawesome/scss/regular.scss';
@import '../fonts/fontawesome/scss/solid.scss';
@import '../fonts/fontawesome/scss/brands.scss';
@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:300,300i,400,400i,500,500i,600,600i,700,700i');
@font-face {
  font-family: 'IBMPlexBold';
  src: url(https://kyisite-assets.s3.eu-west-2.amazonaws.com/webfonts/ibm-plex-sans/IBMPlexSans-Bold.ttf);
}
@font-face {
  font-family: 'IBMPlexMedium';
  src: url(https://kyisite-assets.s3.eu-west-2.amazonaws.com/webfonts/ibm-plex-sans/IBMPlexSans-Medium.ttf);
}
@font-face {
  font-family: 'IBMPlexRegular';
  src: url(https://kyisite-assets.s3.eu-west-2.amazonaws.com/webfonts/ibm-plex-sans/IBMPlexSans-Regular.ttf);
}
@font-face {
  font-family: 'IBMPlexLight';
  src: url(https://kyisite-assets.s3.eu-west-2.amazonaws.com/webfonts/ibm-plex-sans/IBMPlexSans-Light.ttf);
}
