@import './variables';

.table {
  border: none;
  padding: 10px;
  font-size: 14px;
  thead {
    background-color: $dark-gray;
    tr {
      th {
        color: $black;
      }
    }
  }
  tbody {
    tr {
      td {
        border-top: none;
        border-bottom: 1px solid #cecece;
      }
    }
  }
}
