@media (min-width: 992px) {
  .layout-boxed,
  .layout-boxed > .navbar {
    width: 992px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1200px) {
  .layout-boxed,
  .layout-boxed > .navbar {
    width: 1200px;
  }
}
.layout-boxed {
  &.content {
    margin-top: 30px;
    margin-bottom: 30px;
    padding-right: 15px;
    padding-left: 15px; // Added for bootstraps -15px margin on rows
  }
  &.page-content {
    background-color: #f5f5f5;
  }
}

.full-width {
  width: 100%;
}

.kyi-box {
  margin: 0.9%;
  width: 31.1%;
  position: relative;
  padding: 20px 15px 30px 15px;
  font-size: 15px;
  height: 350px;
  font-weight: 400;
}

span.kyi-head {
  display: block;
  text-align: center;
  font-size: 18px;
}

.kyi-content {
  margin-top: 24px;

  .kyi-row {
    margin-bottom: 20px;
    display: table;
    width: 100%;

    .points {
      text-align: right;
      font-size: 30px;
    }

    .record {
      width: 50%;
      float: left;
      font-family: 'IBMPlexRegular', sans-serif;
      font-weight: 300;
    }

    .label span {
      font-family: 'IBMPlexRegular', sans-serif;
      font-weight: 700;
    }
  }
}

.kyi-link {
  position: absolute;
  font-weight: 300;
  bottom: 25px;
  width: 91%;
  text-align: center;

  a {
    color: $black;

    &:before {
      content: '\f061';
      position: absolute;
      left: -10px;
      top: -2px !important;
      font-family: FontAwesome;
      color: $white;
      font-size: 15px;
      padding: 0 4px;
      border-radius: 20px;
      font-weight: 300;
      margin-left: -24px;
    }
  }
}

.gray {
  background: $x-light-gray !important;
  color: #272724;

  .kyi-link {
    color: #272724;

    a {
      color: #272724;

      &:before {
        color: #272724;
      }

      &:link {
        color: #272724;
      }

      &:visited {
        color: #272724;
      }
    }
  }
}

.dark-blue {
  background: #1d3649 !important;
  color: $white;

  .kyi-link {
    color: $white;

    a {
      color: $white;

      &:before {
        color: $white;
      }

      &:link {
        color: $white;
      }

      &:visited {
        color: $white;
      }
    }
  }
}

.magenta {
  background-color: $magenta;
  color: $white;

  .record {
    .points {
      text-align: right;
      font-size: 20px;
      font-weight: 300;
    }
  }

  .kyi-link {
    color: $white;

    a {
      color: $white;

      &:before {
        color: $white;
      }

      &:link {
        color: $white;
      }

      &:visited {
        color: $white;
      }
    }
  }
}

.purple {
  background-color: $purple !important;
  color: $white;

  .kyi-link {
    color: $white;

    a {
      color: $white;

      &:before {
        color: $white;
      }

      &:link {
        color: $white;
      }

      &:visited {
        color: $white;
      }
    }
  }
}

.purple-lite {
  background-color: #9856d4;
  color: $white;

  .kyi-link {
    color: $white;

    a {
      color: $white;

      &:before {
        color: $white;
      }

      &:link {
        color: $white;
      }

      &:visited {
        color: $white;
      }
    }
  }
}

.text-heading {
  word-wrap: break-word;

  a:visited {
    text-decoration: underline;
  }

  table {
    margin-bottom: 40px;
    border-bottom: 1px solid #ededed;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 17px;
    line-height: 2;
    margin: 0 0 20px;
    width: 100%;

    tr {
      display: table-row;
      vertical-align: inherit;
      border-color: inherit;
    }

    th {
      padding: 4px 14px;
      font-weight: inherit;
      font-family: IBMPlexMedium;
    }

    tbody {
      display: table-row-group;
      vertical-align: middle;
      border-color: inherit;
    }

    td {
      border-top: 1px solid #ededed;
    }
  }
}

.concierge-txt {
  padding-bottom: 60px;
  float: left;
  width: 100%;
}

.pd-0 {
  padding: 0;
}

.pt-50 {
  padding-top: 50px;
}

.record-data {
  float: left;
  width: 100%;

  th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    background-color: #008e8b;
    color: $white;
    text-transform: capitalize;
  }

  td {
    padding: 4px 14px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    background-color: $white;
    font-size: 16px;
    font-weight: 300;
  }
}
.supportForm {
  input:not([type='radio']):not([type='checkbox']) {
    width: 100%;
    height: 2.5em;
  }
  .row:nth-child(1) {
    padding-top: 1em;
  }
  .row {
    margin-top: 1em;
    padding-bottom: 1em;
  }
  .row:not(:nth-last-child(-n + 2)) {
    border-bottom: 1px solid $black;
  }
  textarea {
    width: 100%;
    height: 155px;
    resize: none;
  }
  button {
    padding: 12px 50px 12px 50px;
  }
}

button.calendar,
button.calendar:active {
  width: 2.75rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAABHNCS…nlUDaO1vpZRO5NS0x48sIwfPc87xg4B04MCzQi8hIEwe4bl1DnFMCN2zsAAAAASUVORK5CYII=) !important;
  background-repeat: no-repeat;
  background-size: 23px;
  background-position: center;
}
