@import './variables';

.form-control {
  border: 0;
  border-bottom: 2px solid #8d8d8d;
  border-radius: 0;
}

::ng-deep {
  .input-group-addon {
    width: 50px;
  }
}

.btn-primary {
  height: 48px;
  background-color: $blue;
  color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 275px;
}

.btn-inverse {
  height: 48px;
  background-color: $white;
  color: $blue;
  border: 1px solid $blue;

  &:hover {
    background-color: $blue;
    color: $white;
    border: 1px solid $blue;
  }
}

.arrow {
  line-height: 30px;
  margin-left: 25px;
  font-size: 16px;
  color: $white;
  text-align: right;
}

.btn-card {
  height: 40px;
  line-height: 20px;
}

.btn-sales {
  height: 32px;
  font-size: 14px;
  letter-spacing: 0.02px;
}
