//font
$fa-font-path: '/assets/fonts/fontawesome/webfonts';

//Color system

$black: #000;
$light-black: #262626;
$charcoal: #061727;
$blue: #0f61fd;
$blue-secondary: #4589ff;
$dark-blue: #1d364a;
$light-blue: #7cc7fe;
$dark-gray: #dde1e6;
$darker-gray: #646368;
$gray: #ccc;
$light-gray: #f2f4f8;
$x-light-gray: #f9f9f9;
$magenta: #7c1c59;
$purple: #562f72;
$plum: #7c1c58;
$teal: #00bab6;
$light-teal: #87eded;
$white: #fff;
$green: #28a745;
$red: #dc3545;
$menu-blue: #3ed1da;
$menu-darker-blue: #0ba1fd;
$menu-gray: #061727;

$flash-blue: #3ed1da;

$primary-theme-color: $blue;
$secondary-theme-color: $light-blue;

$btn-font-family: 'IBMPlexRegular', sans-serif;
$btn-border-radius: 0;
$primary: $primary-theme-color;

// product catalogue variables
$product-catalogue-main-color: $primary-theme-color;
$product-catalogue-secondary-color: $secondary-theme-color;
$product-catalogue-dark-color: $black;
$product-catalogue-light-color: $white;
